import { FetchError } from '@aurelius/utils'
import { toast } from 'react-toastify'

const FAILED_TO_FETCH = 'failed to fetch'
const NETWORK_ERROR = 'networkerror'
const CANT_CONNECT = 'There was an problem connecting to our servers.'
const PLEASE_TRY_AGAIN = 'Please check your internet connection and try again.'
const toastId = 'failed-to-fetch'

const errors = [FAILED_TO_FETCH, NETWORK_ERROR]

export const toastError = (err: FetchError): Promise<any> => {
  return new Promise(function (_resolve, reject) {
    // failed to fetch errors
    if (errors.some((e) => err.message?.toLowerCase()?.indexOf(e) > -1)) {
      if (typeof window !== 'undefined') {
        toast.error(`${CANT_CONNECT} ${PLEASE_TRY_AGAIN}`, {
          toastId,
        })
      }
      throw reject(new Error(CANT_CONNECT))
    }

    reject(err)
  })
}
