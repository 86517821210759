import { fetchAPI } from '@aurelius/utils'
import { User, invitedUserType, invitedUserSSOType } from '@aurelius/models'
import { toastError } from '../helpers/toastError'
import { UserProfile } from './profile'

const isQA = (process.env.COOKIE_DOMAIN ?? '').indexOf('.qa.') > -1
const isProd = process.env.NODE_ENV === 'production'
const domain = isQA ? 'qa.aureliuslab.com' : 'aureliuslab.com'

export const COOKIE_NAME = `${isProd ? '__Secure-' + domain : 'localhost'}`

export const getJwt = (): string | undefined => {
  if (typeof document === 'undefined') {
    return
  }
  let jwt
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const name = cookies[i].split('=')[0]
    const value = cookies[i].split('=')[1]
    if (name.trim() === COOKIE_NAME) {
      jwt = value
      break
    }
  }
  return jwt
}

export const getJwtFromCookie = (cookie: string): string | undefined => {
  const cookies = cookie.split(';')
  let jwt
  for (let i = 0; i < cookies.length; i++) {
    const name = cookies[i].split('=')[0]
    const value = cookies[i].split('=')[1]
    if (name.trim() === COOKIE_NAME) {
      jwt = value
      break
    }
  }
  return jwt
}

export const isAuthenticated = (): boolean => !!getJwt()

const atob = (base64: string) => {
  if (typeof window === 'undefined') {
    return Buffer.from(base64, 'base64').toString('binary')
  } else {
    return window.atob(base64)
  }
}

export const parseJwt = (jwt: string | undefined): UserProfile | null => {
  if (jwt) {
    try {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const userData = JSON.parse(atob(base64))
      if (Number(userData.exp) * 1000 <= Date.now()) {
        return null
      } else {
        return userData as UserProfile
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  return null
}

export const getUserFromCookie = (): UserProfile | null => {
  const token = getJwt()
  return parseJwt(token)
}

export const getUserFromCookieSSR = (cookie: string): UserProfile | null => {
  const token = getJwtFromCookie(cookie)
  return parseJwt(token)
}

const removeCookies = () => {
  if (typeof document !== 'undefined') {
    document.cookie = `${COOKIE_NAME}=; path=/; domain=${domain}${
      isProd ? '; Secure' : ''
    }; expires=Thu, 01 Jan 1970 00:00:01 GMT`
  }
}

export const logout = async (redirect = true): Promise<void> => {
  try {
    if (typeof window !== 'undefined') {
      const user = getUserFromCookie()
      if (user?.saml) {
        await fetchAPI<{ logout_url: string }>('/v1/saml/logout', {
          method: 'GET',
        })
        window.Intercom?.('shutdown')
        removeCookies()
        if (redirect) {
          window.location.href = process.env.LOGIN_URL ?? '/'
        }
      } else {
        // server side cookie clearing
        await fetchAPI('/v1/logout', { method: 'POST' })
        window.Intercom?.('shutdown')
        removeCookies()
        if (redirect) {
          window.location.href = process.env.LOGIN_URL ?? '/'
        }
      }
    }
  } catch {
    if (redirect) {
      window.location.href = process.env.LOGIN_URL ?? '/'
    }
  }
}

export const login = async (email: string, password: string): Promise<User> =>
  await fetchAPI<User>(
    '/v1/authenticate',
    {
      method: 'POST',
    },
    {
      email,
      password,
    },
  ).catch(toastError)

export const checkConfirmationCode = async (
  key: string,
): Promise<UserProfile> =>
  await fetchAPI<UserProfile>('/v1/invite?key=' + key, {
    method: 'GET',
  }).catch(toastError)

export const completeInvite = async (user: invitedUserType): Promise<User> =>
  await fetchAPI<User>(
    '/v1/invite',
    {
      method: 'POST',
    },
    user,
  ).catch(toastError)

export const completeSSOInvite = async (
  user: invitedUserSSOType,
): Promise<void> =>
  await fetchAPI(
    '/v1/invite/sso',
    {
      method: 'POST',
    },
    user,
  )

export const resetPassword = async (
  email: string,
  confirm: string,
): Promise<void> =>
  await fetchAPI<void>(
    '/v1/resetpassword',
    {
      method: 'POST',
    },
    {
      email,
      confirm,
    },
  ).catch(toastError)

export const resetPasswordConfirm = async (
  key: string,
  password: string,
): Promise<void> =>
  await fetchAPI<void>(
    '/v1/resetpassword/' + key,
    {
      method: 'POST',
    },
    {
      password,
    },
  ).catch(toastError)

export const sso = async (email: string): Promise<{ loginUrl: string }> =>
  await fetchAPI<{ loginUrl: string }>(
    '/v1/saml/login',
    {
      method: 'POST',
    },
    {
      email,
    },
  ).catch(toastError)
