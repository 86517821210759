import { kebabCase, memoize } from 'lodash-es'

// this one only strips "block" elements
const stripConf = {
  allowedTags: [
    'p',
    // 'b',
    // 'i',
    // span,
    'br',
    'ul',
    'ol',
    'li',
    'blockquote',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    // 'big',
    // 'small',
    // 'strike',
  ],
  allowedAttributes: {},
}

export const stripInlineHtml = async (input: string): Promise<string> => {
  const { default: sanitize } = await import('sanitize-html')
  return sanitize(input, stripConf)
}

const process = (
  text: string,
  openTag: string,
  input: string,
  closeTag: string,
): string => text.replace(new RegExp(input, 'gi'), openTag + input + closeTag)

const itterateChildNodes = (
  node: ChildNode,
  openTag: string,
  input: string,
  closeTag: string,
): string => {
  let text = ''
  for (let index = 0; index < node.childNodes.length; index++) {
    const el = node.childNodes[index]
    if (el.nodeType === Node.TEXT_NODE) {
      if (el.textContent) {
        text += process(el.textContent, openTag, input, closeTag)
      }
    } else {
      const tagName = el.nodeName.toLowerCase()
      // self closing or not
      if (tagName === 'br') {
        text += '<br>'
      } else {
        text += `<${tagName}>${itterateChildNodes(
          el,
          openTag,
          input,
          closeTag,
        )}</${tagName}>`
      }
    }
  }
  return text
}

export const htmlStringReplace = async (
  html: string,
  input: string,
  openTag = '<span class="highlight">',
  closeTag = '</span>',
): Promise<string> => {
  // remove all inline elements
  const stripped = await stripInlineHtml(html)

  // loop over remaining block elements and do search/replace
  const div = document.createElement('div')
  div.innerHTML = stripped

  if (div.childNodes && div.childNodes.length > 0) {
    return itterateChildNodes(div, openTag, input, closeTag)
  } else {
    return process(stripped, openTag, input, closeTag)
  }
}

/**
 * Regex used to find style tags in HTML for notes by matching tag ID (used on API)
 */
export const styleTagRegex = (tagId: string) =>
  RegExp(`data-tagid="${tagId}"(\\s?)style="[a-zA-Z0-9:;.\\s-,#()]*"`, 'ig')

/**
 * String used by document.querySelectorAll to get matching note highlights from the DOM
 */
export const styleTagQuery = (tagId: string) => `[data-tagid="${tagId}"]`

/**
 * Used to update color of style tag in HTML for notes
 */
export const syleUpdateTagColor = (
  tagId: string,
  backgroundColor: string,
  foregroundColor: string,
) =>
  `data-tagid="${tagId}" style="background-color:${backgroundColor}; color:${foregroundColor}"`

/**
 * Gives the style tag attributes as an object (useful for setting style props etc)
 */
export const styleUpdateTagObject = (
  tagId: string,
  backgroundColor: string,
  foregroundColor: string,
) => ({
  dataTagId: tagId,
  style: {
    backgroundColor,
    color: foregroundColor,
  },
})

/**
 * Turns style objects into inline style strings
 */
export const inlineStyle = (obj: Record<string, string>) => {
  if (!obj) {
    return ''
  } else {
    const props = Object.keys(obj)

    const styles = props.map(function (key) {
      const prop = kebabCase(key)
      const line = prop.concat(':').concat(obj[key])
      return line
    })
    return styles.join(';')
  }
}

/**
 * Used to "remove" color from tags/highlights
 */
export const defaultColor = 'var(--colors-tags-background)'
export const defaultTextColor = 'var(--colors-typography-notes)'
/**
 * Chooses the highest contrast based on W3C guidelines.
 * @param backgroundColor hex code string (i.e. `#024058`)
 * @returns `#000000` or `#ffffff`
 */
export const lightOrDarkForeground: (
  backgroundColor?: string,
) => '#000000' | '#ffffff' = memoize((backgroundColor) => {
  if (!backgroundColor) {
    return '#000000'
  }
  if (!/^#[0-9A-F]{6}$/i.test(backgroundColor)) {
    throw new Error(
      'Invalid color! Must be a valid 7-character string specifying an RGB color in hexadecimal format.',
    )
  }
  const color = backgroundColor.substring(1, 7) // remove `#`
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map((col) =>
    col <= 0.03928 ? col / 12.92 : Math.pow((col + 0.055) / 1.055, 2.4),
  )
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? '#000000' : '#ffffff'
})
