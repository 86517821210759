import {
  extendTheme,
  ThemeOverride,
  StyleFunctionProps,
} from '@chakra-ui/react'

export const theme: ThemeOverride = extendTheme({
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          alignSelf: 'start', // Aligns the checkbox to the start of the flex container, which is the top in column direction
        },
      },
    },
    Popover: {
      baseStyle: {},
      variants: {
        tooltip: (props: StyleFunctionProps) => ({
          popper: {},
          content: {
            boxShadow: 'sm', // Apply a standard shadow effect
            '--popper-arrow-bg':
              props.colorMode === 'dark' ? undefined : 'var(--colors-popover)',
            bg: 'var(--colors-popover)',
            padding: '10px',
            borderRadius: '2px',
            backgroundColor: 'var(--colors-popover)',
            color: 'var(--colors-buttons-primary-text)',
            fontSize: '12px',
          },
          arrow: {},
        }),
      },
    },
    Tooltip: {
      baseStyle: {
        fontSize: '12px',
        lineHeight: '1.08',
      },
      variants: {
        empty: {
          bg: 'var(--colors-layout-background)',
          color: 'var(--colors-buttons-primary-text)',
          border: '1px solid #00678d',
          borderRadius: '4.5px',
        },
      },
    },
    Drawer: {
      baseStyle: {
        dialog: {
          bg: 'var(--colors-layout-background)',
        },
        header: {},
        body: {
          flex: 'unset',
          marginBottom: '0',
          background: `var(--drawer-body-background-top) center top, var(--drawer-body-background-bottom) center bottom, var(--drawer-body-radial-top) center top, var(--drawer-body-radial-bottom) center bottom`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
          backgroundAttachment: 'local, local, scroll, scroll',
        },
        footer: {
          paddingTop: '8px',
          paddingBottom: '52px',
          marginTop: '0',
          marginBottom: '0',
        },
      },
      sizes: {
        '2xl': { dialog: { maxW: '6xl' } },
      },
    },
    Button: {
      defaultProps: {
        variant: 'none',
      },
      baseStyle: {
        border: 0,
        background: 'var(--colors-buttons-primary-background)',
        backgroundColor: 'var(--colors-buttons-primary-background)',
        color: 'var(--colors-buttons-primary-text)',
        _hover: {
          filter: 'none',
          background: 'var(--colors-buttons-primary-hover)',
          backgroundColor: 'var(--colors-buttons-primary-hover)',
          color: 'var(--colors-buttons-primary-text)',
          _disabled: {
            background: 'var(--colors-buttons-primary-hover)',
            backgroundColor: 'var(--colors-buttons-primary-hover)',
          },
        },
        _disabled: {
          background: 'var(--colors-buttons-primary-hover)',
          backgroundColor: 'var(--colors-buttons-primary-hover)',
          _hover: {
            background: 'var(--colors-buttons-primary-hover)',
            backgroundColor: 'var(--colors-buttons-primary-hover)',
          },
        },
      },
      variants: {
        base: {
          fontSize: '12px',
          fontWeight: '400',
          padding: '8px',
          height: 'auto',
          color: 'var(--colors-typography-menu)',
          border: '1px solid var(--colors-borders-lists)',
          background: 'var(--colors-layout-background)',
          backgroundColor: 'var(--colors-layout-background)',
          _hover: {
            filter: 'none',
            background: 'var(--colors-button-reset-hover)',
            backgroundColor: 'var(--colors-button-reset-hover)',
            color: 'var(--colors-typography-menu)',
            border: '1px solid var(--colors-borders-lists)',
          },
          _active: {
            border: '1px solid var(--colors-borders-lists)',
            fontWeight: 'bold',
            backgroundColor: 'var(--colors-borders-lists)',
            background: 'var(--colors-borders-lists)',
          },
        },
        reset: {
          fontSize: '12px',
          fontWeight: '400',
          padding: '8px',
          height: 'auto',
          color: 'var(--colors-typography-link)',
          background: 'var(--colors-button-reset-background)',
          backgroundColor: 'var(--colors-button-reset-background)',
          _hover: {
            filter: 'none',
            background: 'var(--colors-button-reset-hover)',
            backgroundColor: 'var(--colors-button-reset-hover)',
            color: 'var(--colors-button-reset-text)',
          },
        },
        action: {
          fontSize: '16px',
          fontWeight: 'bold',
          padding: '8px 16px',
          minWidth: 'auto',
          background: 'var(--colors-action-bar-background)',
          backgroundColor: 'var(--colors-action-bar-background)',
          color: 'var(--colors-buttons-primary-text)',
          _hover: {
            background: 'var(--colors-button-reset-hover)',
            backgroundColor: 'var(--colors-button-reset-hover)',
            color: 'white',
          },
        },
        warn: {
          background: 'var(--colors-button-errors)',
          backgroundColor: 'var(--colors-button-errors)',
          _hover: {
            background: 'var(--colors-typography-errors)',
            backgroundColor: 'var(--colors-typography-errors)',
            color: 'white',
          },
        },
        menu: {
          fontSize: '12px',
          fontWeight: '400',
          padding: '8px',
          height: 'auto',
          color: 'var(--colors-typography-menu)',
          background: 'var(--colors-borders-lists)',
          backgroundColor: 'var(--colors-borders-lists)',
          _hover: {
            filter: 'none',
            background: 'var(--colors-button-reset-hover)',
            backgroundColor: 'var(--colors-button-reset-hover)',
            color: 'var(--colors-typography-menu)',
            border: 'none',
          },
        },
        menuitem: {
          fontSize: '12px',
          fontWeight: '400',
          padding: '8px',
          height: 'auto',
          color: 'var(--colors-typography-menu)',
          background: 'var(--colors-borders-lists)',
          backgroundColor: 'var(--colors-borders-lists)',
          _hover: {
            filter: 'none',
            background: 'var(--colors-button-reset-hover)',
            backgroundColor: 'var(--colors-button-reset-hover)',
            color: 'var(--colors-typography-menu)',
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: 'var( --colors-typography-primary)',
      },
    },
    CloseButton: {
      baseStyle: {
        border: 0,
        background: 'var(--colors-buttons-alt-background)',
        backgroundColor: 'var(--colors-buttons-alt-background)',
        color: 'var(--colors-typography-link)',
        svg: {
          color: 'var(--colors-typography-link)',
        },
        _hover: {
          background: '#ececec',
          backgroundColor: '#ececec',
          color: 'var(--colors-typography-link)',
        },
      },
    },
    List: {
      baseStyle: {
        item: {
          '&::marker': {
            color: 'var(--colors-typography-link)',
            fontSize: '14px',
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: 'var(--colors-layout-background)',
          color: 'var(--colors-primary-text)',
        },
        dialogContainer: {},
        header: {},
        body: {},
        footer: {},
      },
      variants: {
        // this variant is the "ActionBar"
        action: {
          dialog: {
            bg: 'var(--colors-action-bar-background)',
            color: 'var(--colors-buttons-primary-text)',
            borderRadius: '8px',
            margin: 0,
            width: 'auto',
            maxWidth: 'none', // or you could set a specific max-width
            height: 'auto',
            maxHeight: '90vh', // for example, to ensure it doesn't grow beyond the viewport height
            overflowY: 'auto', // ensures that if the content is too tall, it's scrollable
          },
          dialogContainer: {
            width: 'auto',
            height: 'auto !important',
            top: null,
            position: 'fixed',
            left: '50%',
            transform: 'translate(-50%)',
            bottom: '24px',
            maxWidth: '912px',
            zIndex: '4',
          },
          closeButton: {
            border: 0,
            background: 'var(--colors-action-bar-background)',
            backgroundColor: 'var(--colors-action-bar-background)',
            color: 'white',
            svg: {
              color: 'white',
            },
            _hover: {
              background: 'var(--colors-button-reset-hover)',
              backgroundColor: 'var(--colors-button-reset-hover)',
              color: 'white',
              svg: {
                color: 'white',
              },
            },
          },
          header: {
            padding: 4,
            fontSize: 'lg',
            fontWeight: 'bold',
            color: 'white',
          },
          body: {
            padding: 4,
            fontSize: 'md',
          },
          footer: {
            padding: 4,
            display: 'flex',
            justifyContent: 'flex-end',
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        // define the part you're going to style
        button: {
          // this will style the MenuButton component
          fontWeight: 'medium',
          bg: 'var(--colors-layout-background)',
          color: 'var(--colors-typography-primary)',
          _hover: {
            bg: 'var(--colors-layout-background)',
            color: 'white',
          },
        },
        list: {
          // this will style the MenuList
          margin: '-8px 0 0 0',
          padding: 0,
          border: '1px solid var(--colors-borders)',
          borderRadius: '0px',
          bg: 'var(--colors-layout-background)',
        },
        item: {
          // this will style the MenuItem and MenuItemOption components
          color: 'var(--colors-typography-primary)',
          bg: 'var(--colors-layout-background)',
          margin: 0,
          padding: 0,
          border: 'none',
          _hover: {
            bg: 'var(--colors-layout-background)',
          },
          _focusVisible: {
            bg: 'var(--colors-layout-background)',
          },
        },
        groupTitle: {
          // this will style the text defined by the title prop
          // in the MenuGroup and MenuOptionGroup components
          textTransform: 'uppercase',
          color: 'white',
          textAlign: 'center',
          letterSpacing: 'wider',
          opacity: '0.7',
        },
        command: {
          // this will style the text defined by the command
          // prop in the MenuItem and MenuItemOption components
          opacity: '0.8',
          fontFamily: 'mono',
          fontSize: 'sm',
          letterSpacing: 'tighter',
          pl: '4',
        },
        divider: {
          // this will style the MenuDivider component
          my: '4',
          borderColor: 'white',
          borderBottom: '2px dotted',
        },
      },
      variants: {
        assistant: {
          list: {
            width: '140px',
            maxWidth: '140px',
            minWidth: '140px',
            border: 'none',
          },
          item: {
            fontWeight: 'medium',
            fontSize: '12px',
            width: '140px',
            background: 'var(--colors-buttons-primary-background)',
            backgroundColor: 'var(--colors-buttons-primary-background)',
            color: 'var(--colors-buttons-primary-text)',
            borderRadius: '0',
            _hover: {
              filter: 'none',
              background: 'var(--colors-buttons-primary-hover) !important',
              backgroundColor: 'var(--colors-buttons-primary-hover) !important',
              color: 'var(--colors-buttons-primary-text) !important',
            },
          },
        },
      },
    },
    Alert: {
      // Style for the base style of the Alert
      baseStyle: {
        container: {
          background: 'rgba(250, 105, 17, 0.1)',
          backgroundColor: 'rgba(250, 105, 17, 0.1)',
          borderRadius: '0',
        },
        icon: {
          color: '#fa6911',
        },
        title: {
          fontWeight: 'normal',
          color: '#903d0a',
          fontSize: '12px',
          lineHeight: '16px',
        },
        description: {
          fontWeight: 'normal',
          color: '#903d0a',
        },
      },
    },
  },
})
