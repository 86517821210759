import { Roboto, Lato } from 'next/font/google'

// fonts
const roboto = Roboto({
  weight: ['300', '400', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  variable: '--font-roboto',
  fallback: ['Arial', 'Helvetica', 'sans-serif'],
})
const lato = Lato({
  weight: ['400', '700'],
  style: ['normal'],
  subsets: ['latin'],
  variable: '--font-lato',
})

export const fonts = `${roboto.variable} ${lato.variable}`
