export * from './accessLevels'
export * from './fetch'
export * from './html'
export * from './image'
export * from './sanitize'
export * from './selection'
export * from './clips'
export * from './colors/getColorFromName'
export * from './base64decode'
export * from './math'

declare global {
  interface Window {
    Intercom?: Function
    location: Location
    Sentry?: { setUser: Function }
  }
}
