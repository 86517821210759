const generateBlankPlaceholder = (): Promise<Blob> => {
  const canvas = document.createElement('canvas')
  canvas.width = 200
  canvas.height = 400
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.fillStyle = 'black'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
  }
  return new Promise((resolve, reject) => {
    return canvas.toBlob((results) => {
      if (results != null) {
        resolve(results)
      } else {
        reject(new Error('Could not generate placeholder.'))
      }
    }, 'image/jpeg')
  })
}
/**
 *
 * @param file Video file to turn into thumbnail
 * @param height Height in pixels
 * @param width Width in pixels
 * @param time Time to capture in seconds
 */
export const videoToThumbnail = ({
  dataURL,
  type,
  height,
  width,
  onDurationChange,
}: {
  dataURL: string
  type: string
  height?: number
  width?: number
  onDurationChange?: (event: any) => any
}): Promise<Blob> =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video')
      video.setAttribute('crossorigin', 'anonymous')

      // if the video can be played, it'll be 'maybe', 'probably' otherwise it is an empty string
      if (video.canPlayType(type) == '') {
        generateBlankPlaceholder()
          .then((placeholder) => {
            if (placeholder != null) {
              resolve(placeholder)
            } else {
              reject(new Error('Could not generate placeholder.'))
            }
          })
          .catch((ex) => reject(ex))
      } else {
        video.oncanplay = (): void => {
          try {
            video.currentTime = Math.floor(video.duration / 2)
            const videoCanvas = document.createElement('canvas')

            videoCanvas.height = height ?? video.videoHeight
            videoCanvas.width = width ?? video.videoWidth
            videoCanvas.getContext('2d')?.drawImage(video, 0, 0)
            resolve(
              new Promise((resolve) => {
                return videoCanvas.toBlob((results) => {
                  if (results != null) {
                    resolve(results)
                  } else {
                    reject(new Error('Could not generate placeholder.'))
                  }
                }, 'image/jpeg')
              }),
            )
          } catch (ex) {
            reject(ex)
          }
        }
        if (onDurationChange != null) {
          video.ondurationchange = onDurationChange
        }
        video.onerror = (ex): void => {
          reject(ex)
        }
        video.src = dataURL
      }
    } catch (ex) {
      reject(ex)
    }
  })

const loadImage = (string: string, maxSize = 400): Promise<Blob> =>
  new Promise((resolve, reject) => {
    try {
      const img = new Image()
      img.onload = (): void => {
        try {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.width = img.width
          canvas.height = img.height
          context?.drawImage(img, 0, 0)
          if (img.width > 400 || img.height > 400) {
            if (img.width > img.height) {
              canvas.height = (img.height / img.width) * maxSize
              canvas.width = maxSize
            } else {
              canvas.width = (img.width / img.height) * maxSize
              canvas.height = maxSize
            }
            context?.drawImage(canvas, 0, 0, canvas.width, canvas.height)
            context?.drawImage(img, 0, 0, canvas.width, canvas.height)
          }
          resolve(
            new Promise((resolve) => {
              return canvas.toBlob((results) => {
                if (results != null) {
                  resolve(results)
                } else {
                  reject(new Error('Could not generate placeholder.'))
                }
              }, 'image/jpeg')
            }),
          )
        } catch (ex) {
          reject(ex)
        }
      }
      img.onerror = (ex): void => {
        reject(ex)
      }
      img.src = string
    } catch (ex) {
      reject(ex)
    }
  })

export const imageToThumbnail = async (dataURL: string): Promise<Blob> =>
  await loadImage(dataURL)
