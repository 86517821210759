import { sanitizeHtml } from '../sanitize'

export const getRange = (): Range | undefined => {
  const sel = window.getSelection()
  if (sel?.getRangeAt && sel?.rangeCount) {
    return sel.getRangeAt(0)
  } else {
    return
  }
}

export const restoreRange = (range: Range | undefined): void => {
  if (range) {
    if (window.getSelection) {
      const sel = window.getSelection()
      sel?.removeAllRanges()
      sel?.addRange(range)
    }
  }
}

/**
 * Tests if the cursor is at the beginning or end of the editor
 * @param el contentEditableDiv
 */
export const testSelection = (
  el: HTMLDivElement,
): { start: boolean; emptyLine: boolean; end: boolean } => {
  let start = false
  let emptyLine = false
  let end = false
  let selRange, testRange

  const sel = window.getSelection()

  if (
    sel?.anchorNode?.textContent === '' ||
    (sel?.anchorNode?.nodeName === '#text' &&
      sel?.anchorNode?.parentNode?.previousSibling?.textContent === '')
  ) {
    emptyLine = true
  }
  if (sel?.rangeCount) {
    selRange = sel.getRangeAt(0)
    testRange = selRange.cloneRange()

    testRange.selectNodeContents(el)
    testRange.setEnd(selRange.startContainer, selRange.startOffset)
    start = testRange.toString() == ''

    testRange.selectNodeContents(el)
    testRange.setStart(selRange.endContainer, selRange.endOffset)

    end = testRange.toString() == ''
  }

  return { start, emptyLine, end }
}

/**
 * This will "cut" the contents after the cursor and return it as html
 *
 * @param el contentEditableDiv
 */
export const splitNode = async (
  el: HTMLDivElement,
): Promise<{ before: string; after: string }> => {
  //#region
  // TODO: Rewrite everything using Ranges and setStartBefore/setEndAfter along with extract contents
  // const rangeBefore = document.createRange()
  // const rangeAfter = document.createRange()

  // rangeBefore.setStart(range.startContainer, 0)
  // rangeBefore.setEnd(range.startContainer, range.startOffset)

  // if (el) {
  //   rangeBefore.setStartBefore(el)
  // }

  // rangeAfter.setStart(range.endContainer, range.endOffset)
  // //@ts-expect-error range.endContainer.length actually exists
  // rangeAfter.setEnd(range.endContainer, range.endContainer.length)

  // if (el) {
  //   rangeAfter.setEndAfter(el)
  // }
  //#end-region

  const selection = document.getSelection()
  if (selection == null || el.lastChild == null) {
    return { before: '', after: '' }
  }

  const range = selection.getRangeAt(0)
  if (!range.collapsed) {
    return { before: '', after: '' }
  }

  selection.extend(el.lastChild, 1)

  const extraction = range?.cloneContents()
  if (extraction == null) {
    return { before: '', after: '' }
  }
  // get the raw HTML
  const div = document.createElement('div')
  div.appendChild(extraction?.cloneNode(true))

  // remove that html to get the 'before'
  const before = el.innerHTML
    .substring(0, div.innerHTML.length)
    .replace(/<p><br><\/p>$/, '') // remove extra line breaks at the end (FF)

  // Remove extra line break from the 'after'
  if (div.firstChild) {
    try {
      div.removeChild(div.firstChild)
    } catch (ex) {
      // this can happen when editing inline, and by some browser plug-ins
      console.error(ex)
    }
  }
  const beforeResult = await sanitizeHtml(before)
  const afterResult = await sanitizeHtml(div.innerHTML)
  return { before: beforeResult, after: afterResult }
}

export const surroundSelection = (
  elType: string,
  props: Record<string, string> = {},
  range: Range,
  elem: HTMLElement,
): string => {
  const el = document.createElement(elType)

  Object.keys(props).forEach((key) => el.setAttribute(key, props[key]))

  range.surroundContents(el)
  return elem.innerHTML
}
