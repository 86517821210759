import { Company, User } from '@aurelius/models'
let companyCache: {
  companyId: string
  name?: string
}

export const intercomOptions = (user?: User, company?: Company) => {
  const details = {
    appId: process.env.INTERCOM_APP_ID,
  } as any

  if (user) {
    details.name = user?.name
    details.userId = user?.uuid ?? user?.id // legacy ids
    details.email = user?.email
    details.createdAt = Math.round(new Date(user?.createdAt).getTime() / 1000)
  }

  if (company) {
    if (
      companyCache?.companyId != (company.id ?? company._id) &&
      companyCache?.name != company.name
    ) {
      companyCache = {
        companyId: company.id ?? company._id,
        name: company.name,
      }
    }
  } else if (
    user?.company &&
    (typeof user?.company === 'string' || user?.company instanceof String)
  ) {
    companyCache = {
      companyId: user?.company.toString(),
    }
  }

  if (companyCache) {
    details.company = companyCache
  }

  return details
}
