import { IOptions } from 'sanitize-html'

// NOTE: DO NOT include <p> tag or <div>, we don't want extra markup floating around from other browsers
const sanitizeConf: IOptions = {
  allowedTags: [
    'p',
    'b',
    'i',
    'a',
    'br',
    'ul',
    'ol',
    'li',
    'blockquote',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'big',
    'small',
    'strike',
    'span',
    'mark',
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    a: ['href'],
    span: ['style'],
    mark: ['style', 'data-*'],
  },
}
const stripConf: IOptions = {
  allowedTags: [],
  disallowedTagsMode: 'discard',
  allowedAttributes: {},
}

// runs the sanitizer and fixes cross browser inconsistencies
export const sanitizeHtml = async (input: string): Promise<string> => {
  const { default: sanitize } = await import('sanitize-html')
  return sanitize(input, sanitizeConf) // sanitize
    .replace(/<br \/>/g, '<br>') // get rid of weird FF break tags
    .replace(/&nbsp;|\u202F|\u00A0/g, ' ') //replaces weird spaces with regular ones
    .replace(/<p><\/p>/g, '') // remove empty <p> tags
    .replace(/<p><p>/g, '<p>') // remove nested <p>
    .replace(/<\/p><\/p>/g, '</p>') // remove nested <p>
}

export const stripHtml = async (input: string): Promise<string> => {
  const { default: sanitize } = await import('sanitize-html')

  return sanitize(input, stripConf)
} // strip everything
