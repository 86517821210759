export const clipSecondsToFormatted = (seconds: number) => {
  if (isNaN(seconds)) {
    return '00:00:00'
  }
  if (seconds <= 0) {
    return '00:00:00'
  }
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours().toString().padStart(2, '0')
  const mm = date.getUTCMinutes().toString().padStart(2, '0')
  const ss = date.getUTCSeconds().toString().padStart(2, '0')

  if (hh) {
    return `${hh}:${mm}:${ss}`
  }

  return `00:${mm}:${ss}`
}

export const clipTimeToSeconds = (time: string) => {
  const arrTime = time.split(':')
  if (arrTime.length === 3) {
    return (
      Number(arrTime[0]) * 3600 + Number(arrTime[1]) * 60 + Number(arrTime[2])
    )
  }
  return 0
}
